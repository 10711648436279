<template>
    <div>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
        >
            <div>
                <el-button type="primary" @click="dataExport">导出电费单</el-button>
                <el-button type="primary" @click="dataExportInfo">导出地方水利基金单</el-button>
            </div>
            <el-row :gutter="24">
                <el-col :span="6">
                    <el-form-item :label="'抄表月份'" prop="recordMonth" :rules="{ required: true, message: this.$t('common.select', {item: '抄表月份'}), trigger: 'blur' }">
                        <el-date-picker
                            v-model="formData.recordMonth"
                            style="width: 100%"
                            type="month"
                            :placeholder="'请选择'"
                            value-format="yyyy-MM"
                            @change="switchMonthHandler"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="本月用电总量(kVH)">
                        <el-input v-model="cmonthDegreeAll" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="备用电源点电费(元)">
                        <el-input-number  :disabled="isDisable" v-model="formData.backupPower" :precision="2"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :span="4">
                    <el-form-item label="本月电费(元)">
                        <el-input :value="cmonthDegreeAmount" disabled></el-input>
                    </el-form-item>
                </el-col><el-col :span="4">
                <el-form-item label="丰枯标志" prop="waterMark">
                    <el-input :value="waterMarkName" disabled></el-input>
                </el-form-item>
            </el-col>
            </el-row>

            <el-row v-for="(item,index) in formData.degreeList" :key="index" class="item-card">
                <el-row>
                    <el-col>
                        <el-form-item  :label="'计量点'" :prop="`degreeList[${index}].planMeteringName`">
                            <el-input v-model="item.planMeteringName" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-form-item  label="电价倍率" :prop="`degreeList[${index}].electricityRate`">
                            <el-input-number  :disabled="isDisable" v-model="item.electricityRate" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="基本电费单价" :prop="`degreeList[${index}].basePrice`">
                            <el-input-number  :disabled="isDisable" v-model="item.basePrice" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="功率因数标准" :prop="`degreeList[${index}].powerFactorStandard`" style="width: 100%">
                            <el-input-number  :disabled="isDisable" v-model="item.powerFactorStandard" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="用电容量" :prop="`degreeList[${index}].capacity`"
                                      v-if="item.chargeCollectType === 1"
                                      style="width: 100%">
                            <el-input-number  :disabled="isDisable" v-model="item.capacity" :min="0" :precision="4" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                        <el-form-item label="用电需量" :prop="`degreeList[${index}].capacity`"
                                      :rules="{ required: true, message: '需量不能为空', trigger: 'blur' }"
                                      v-if="item.chargeCollectType === 2"
                                      style="width: 100%">
                            <el-input-number  :disabled="isDisable" v-model="item.capacity" :min="0" :precision="4" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-form-item  label="用电性质" :prop="`degreeList[${index}].elecType`">
                            <el-input :value="elecTypeName(index)" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item  label="功率因数" :prop="`degreeList[${index}].powerFactor`">
                            <el-input :value="factorAdjustedPowerPrice(index).factor" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item  label="调整系数" :prop="`degreeList[${index}].adjustmentFactor`">
                            <el-input :value="factorAdjustedPowerPrice(index).adjustmentStandard" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-form-item  label="上月表示数(总)" :prop="`degreeList[${index}].lastDegree`">
                            <el-input :value="lastDegree(index)" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="本月表示数(总)" :prop="`degreeList[${index}].degree`">
                            <el-input :value="degree(index)" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="抄见电量(总)" style="width: 100%">
                            <el-input :value="cmonthDegree(index)" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-form-item  label="基金(峰总)" :prop="`degreeList[${index}].peakFundRate`">
                            <el-input v-model="item.peakFundRate" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="基金(平总)" :prop="`degreeList[${index}].flatFundRate`">
                            <el-input v-model="item.flatFundRate" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="基金(谷总)" style="width: 100%" :prop="`degreeList[${index}].valleyFundRate`">
                            <el-input v-model="item.valleyFundRate" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-form-item  label="上月表示数有功(峰)" :prop="`degreeList[${index}].lastPeakDegree`">
                            <!--<el-input-number
                                v-model="item.lastPeakDegree"
                                :disabled="lastDataDisable(index)"
                                :min="0"
                                :precision="2"
                                :step="1"
                                style="width: 100%"
                            ></el-input-number>-->
                            <el-input-number
                                :disabled="isDisable"
                                v-model="item.lastPeakDegree"
                                :min="0"
                                :precision="2"
                                :step="1"
                                style="width: 100%"
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="本月表示数有功(峰)" :prop="`degreeList[${index}].peakDegree`" :rules="{ required: true, message: '本月度数不能为空', trigger: 'blur' }">
                            <el-input-number  :disabled="isDisable" v-model="item.peakDegree" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="抄见电量有功(峰)" style="width: 100%">
                            <el-input  :value="cmonthPeakDegree(index)" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="电费单价(峰)" :prop="`degreeList[${index}].peakPrice`" style="width: 100%">
                            <el-input-number  :disabled="isDisable" v-model="item.peakPrice"  style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="电费(峰)" style="width: 100%">
                            <el-input :value="peakAmount(index)" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-form-item  label="上月表示数有功(平)" :prop="`degreeList[${index}].lastFlatDegree`">
                            <!--<el-input-number
                                v-model="item.lastFlatDegree"
                                :disabled="lastDataDisable(index)"
                                :min="0"
                                :precision="2"
                                :step="1"
                                style="width: 100%"
                            ></el-input-number>-->
                            <el-input-number
                                :disabled="isDisable"
                                v-model="item.lastFlatDegree"
                                :min="0"
                                :precision="2"
                                :step="1"
                                style="width: 100%"
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="本月表示数有功(平)" :prop="`degreeList[${index}].flatDegree`" :rules="{ required: true, message: '本月度数不能为空', trigger: 'blur' }">
                            <el-input-number  :disabled="isDisable" v-model="item.flatDegree" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="抄见电量有功(平)" style="width: 100%">
                            <el-input :value="cmonthFlatDegree(index)" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="电费单价(平)" :prop="`degreeList[${index}].flatPrice`" style="width: 100%">
                            <el-input-number :disabled="isDisable" v-model="item.flatPrice" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="电费(平)" style="width: 100%">
                            <el-input :value="flatAmount(index)" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-form-item  label="上月表示数有功(谷)" :prop="`degreeList[${index}].lastValleyDegree`">
                            <!--<el-input-number
                                v-model="item.lastValleyDegree"
                                :disabled="lastDataDisable(index)"
                                :min="0"
                                :precision="2"
                                :step="1"
                                style="width: 100%"
                            ></el-input-number>-->
                            <el-input-number
                                :disabled="isDisable"
                                v-model="item.lastValleyDegree"
                                :min="0"
                                :precision="2"
                                :step="1"
                                style="width: 100%"
                            ></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="本月表示数有功(谷)" :prop="`degreeList[${index}].valleyDegree`" :rules="{ required: true, message: '本月度数不能为空', trigger: 'blur' }">
                            <el-input-number  :disabled="isDisable" v-model="item.valleyDegree" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="抄见电量有功(谷)" style="width: 100%">
                            <el-input :value="cmonthValleyDegree(index)" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="电费单价(谷)" :prop="`degreeList[${index}].valleyPrice`" style="width: 100%">
                            <el-input-number  :disabled="isDisable" v-model="item.valleyPrice" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="电费(谷)" style="width: 100%">
                            <el-input  :value="valleyAmount(index)" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-form-item  label="上月抄见数有功(总)" :prop="`degreeList[${index}].lastTotalFromTable`">
                            <el-input-number  :disabled="isDisable" v-model="item.lastTotalFromTable" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="本月抄见数有功(总)" :prop="`degreeList[${index}].totalFromTable`" :rules="{ required: true, message: '本月本月抄见数(总)不能为空', trigger: 'blur' }">
                            <el-input-number  :disabled="isDisable" v-model="item.totalFromTable" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="抄见电量有功(总)" :prop="`degreeList[${index}].cmonthPositiveReactivePower`" style="width: 100%">
                            <el-input :value="cTotalFromTable(index)" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="5">
                        <el-form-item  label="上月正向无功(总)" :prop="`degreeList[${index}].lastPositiveReactivePower`">
                            <el-input-number  :disabled="isDisable" v-model="item.lastPositiveReactivePower" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="本月正向无功(总)" :prop="`degreeList[${index}].positiveReactivePower`" :rules="{ required: true, message: '本月度数不能为空', trigger: 'blur' }">
                            <el-input-number  :disabled="isDisable" v-model="item.positiveReactivePower" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="5">
                        <el-form-item label="正向无功抄见电量(总)" :prop="`degreeList[${index}].cmonthPositiveReactivePower`" style="width: 100%">
                            <el-input  :value="cmonthPositiveReactivePower(index)" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="4">
                        <!--<el-form-item  label="退补电费" :prop="`degreeList[${index}].lastValleyDegree`">
                            <el-input :value="refundAmount(index)" disabled style="width: 100%"></el-input>
                        </el-form-item>-->
                        <el-form-item label="退补电费" :prop="`degreeList[${index}].refundElectricityFees`" style="width: 100%">
                            <el-input-number  :disabled="isDisable" v-model="item.refundElectricityFees" :precision="2" :step="1" style="width: 100%"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="基本电费">
                            <el-input :value="baseAmount(index)" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="调整电费" :prop="`degreeList[${index}].cmonthValleyDegree`" style="width: 100%">
                            <el-input :value="adjustmentAmount(index)" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="电费(总)" :prop="`degreeList[${index}].valleyPrice`" style="width: 100%">
                            <el-input :value="amount(index)" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="电费发票金额" :prop="`degreeList[${index}].valleyPrice`" style="width: 100%">
                            <el-input :value="invoiceAmount(index)" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-form-item label="收费金额合计" :prop="`degreeList[${index}].valleyAmount`" style="width: 100%">
                            <el-input :value="chargeAmount(index)" disabled style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item style="margin-left: 15px" :label="'电表照片'" :prop="`degreeList[${index}].file`">
                            <ex-uploader  :disabled="isDisable" v-model="item.file" :singleFileMode='true'></ex-uploader>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-row>

        </el-form>
    </div>
</template>
<script>
import numeral from 'numeral';
import dayjs from 'dayjs';
import {previewPdf, fileDownload} from '@/utils';
export default {
    name: 'addDegreeReadingForm',
    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
        customerInfo: {},
    },
    data() {
        let thisMonth = function doHandleMonth() {
            let myDate = new Date();
            let tMonth = myDate.getMonth();
            let tYear = myDate.getFullYear();
            let m = tMonth + 1;
            if (m.toString().length === 1) {
                m = '0' + m;
            }
            return tYear + '-' + m;
        };
        let validatorHasNumber = function(rule, value, callback) {
            if (value === '' || value === null || value === '0.0' || value === '0.00') {
                return callback(new Error('不能为空或为0！'));
            } else if (!/(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/.test(value) && value < 0.01) {
                return callback(new Error('必须大于0，且最多保留2位小数!'));
            }
            callback();
        };
        return {
            formData: {
                cid: this.id,
                recordMonth: thisMonth(),
                degreeList: [],
                backupPower: null, //备用电源点电费
                customerInfo: {}, //用户信息
            },
            formRules: {
                cmonthDegreeAll: {
                    required: true,
                    validator: validatorHasNumber,
                    trigger: 'blur',
                },
                cmonthDegreeAmount: {
                    required: true,
                    validator: validatorHasNumber,
                    trigger: 'blur',
                },
            },
            imageUrl: '',
            uploadHeaders: {
                token: this.$store.state.token
            },
            factorAdjustedPowerPrices: [],
            isDisable: false
        };
    },
    methods: {
        getDisabled() {
            let cid = this.customerInfo.cid;
            this.$client.getLastInfo(cid).then(res => {
                let lastMonth = res.data.recordMonth;
                let time = this.formData.recordMonth;
                let nowMonth = time.replace('-', '');
                if (lastMonth && nowMonth) {
                    let s = lastMonth * 1 - nowMonth * 1;
                    if (s > 0) {
                        this.isDisable = true;
                    } else {
                        this.isDisable = false;
                    }
                    this.$emit('geIsDisable', this.isDisable);
                }
            });
        },
        changeCmonthDegree(planMeteringId) {
            let count = 0;
            for (let item of this.formData.degreeList) {
                if (item.planMeteringId === planMeteringId) {
                    if (item.degree > 0 && item.degree < item.lastDegree) {
                        this.$message.error('本月度数不能小于上月度数!');
                    }
                    if (item.degree > 0 && item.degree >= item.lastDegree) {
                        item.cmonthDegree = item.degree - item.lastDegree;
                    } else {
                        item.cmonthDegree = 0;
                    }
                }
                count += item.cmonthDegree;
            }
            this.formData.cmonthDegreeAll = count;
        },
        switchMonthHandler() {
            this.getDisabled();
            this.loadLastMonthData();
        },
        submitHandle(cb) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    for (let item of this.formData.degreeList) {
                        let tempDegree = (item.peakDegree || 0) + (item.flatDegree || 0) + (item.valleyDegree || 0);
                        if (tempDegree < item.lastDegree) {
                            this.$message.error('本月度数不能小于上月度数!');
                            return;
                        }
                    }
                    const cmonthDegreeAll = this.cmonthDegreeAll;
                    const cmonthDegreeAmount = this.cmonthDegreeAmount;
                    const data = {...this.formData, cmonthDegreeAll, cmonthDegreeAmount};
                    this.$client.addCustomerDegreeRecord(data).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success',
                        });
                        cb();
                    });
                }
            });
        },
        loadLastMonthData() {
            //加载当前月抄表所需数据
            const param = {
                recordMonth: this.formData.recordMonth,
                id: this.id
            };
            this.$client.getCustomerDegreeRecord(param).then(result => {
                this.formData = result.data;
            });
        },
        listAllFactorAdjustedPowerPrice() {
            this.$client.listFactorAdjustedPowerPrice().then((ret) => {
                this.factorAdjustedPowerPrices = ret.data;
            });
        },
        dataExportInfo() {
            let powerInfoList = this.getPowerInfo();
            let electricPeakQuantity = 0;
            let electricFlatQuantity = 0;
            let electricValleyQuantity = 0;
            powerInfoList.forEach(item => {
                electricPeakQuantity = electricPeakQuantity + item.electricPeakQuantity;
                electricFlatQuantity = electricFlatQuantity + item.electricFlatQuantity;
                electricValleyQuantity = electricValleyQuantity + item.electricValleyQuantity;
            });
            fileDownload('business/customer/dataExportInfo', {method: 'POST',
                data: {
                    waterMarkName: this.waterMarkName.substr(0, 1), //丰枯标志
                    recordMonth: this.formData.recordMonth, //电费月份
                    customerName: this.customerInfo.customerName, //用户名称
                    elecTypeName: this.formData.degreeList[0].elecType === 1 ? '大工业用电_1-10千伏_丰枯' : '一般工商业及其他用电',
                    electricPeakQuantity, //电量（峰）
                    electricFlatQuantity, //电量（平）
                    electricValleyQuantity, //电量（谷）
                    electricAllQuantity: this.formData.cmonthDegreeAll, //总用电量
                    printTime: dayjs().format('YYYY年MM月DD日'), //打印日期
                    fund: this.fund,
                }});
        },
        dataExport() {
            //获取当前时间
            fileDownload('business/customer/download', {method: 'POST',
                data: {
                    customerName: this.customerInfo.customerName, //用户名称
                    customerNumber: this.customerInfo.customerNumber, //用户编号
                    cid: this.customerInfo.cid, //用户id
                    customerAddr: '', //用户地址
                    printTime: dayjs().format('YYYY年MM月DD日'), //打印日期
                    breakAContractStart: dayjs().add(10, 'day').format('YYYY年MM月DD日'), //违约金起始日
                    //基本信息
                    cmonthDegreeAmount: this.cmonthDegreeAmount, //应缴电费合计
                    elecTypeName: this.formData.degreeList[0].elecType === 1 ? '大工业用电_1-10千伏_丰枯' : '一般工商业及其他用电', //用电性质
                    waterMarkName: this.waterMarkName, //丰枯标志
                    recordMonth: this.formData.recordMonth.split('-').join(''), //电费月份
                    lastRecordMonth: dayjs(this.formData.recordMonth).format('YYYYMMDD'), //上次抄表日期
                    nowRecordMonth: dayjs(this.formData.recordMonth).add(1, 'month').format('YYYYMMDD'), //本次抄表日期
                    cmonthDegreeAll: this.formData.cmonthDegreeAll, //当月总用电量
                    powerInfoList: this.getPowerInfo(), //电量信息
                }});
        },
        getPowerInfo() {
            let powerInfoList = [];
            let list = this.formData.degreeList;
            if (list.length > 0) {
                for (let i = 0; i < list.length; i++) {
                    const powerInfo = {
                        //电量信息
                        powerApNubmer: list[i].powerApNubmer, //表计资产编号
                        electricityRate: list[i].electricityRate, //倍率
                        cmonthDegreeAll: this.cmonthDegree(i), //合计电量
                        lastDegree: list[i].lastDegree, //上次表示数（总）
                        degree: list[i].degree, //本次表示数（总）
                        lastPeakDegree: list[i].lastPeakDegree, //上次表示数(峰)
                        peakDegree: list[i].peakDegree, //本次表示数（峰）
                        lastFlatDegree: list[i].lastFlatDegree, //上次表示数（平）
                        flatDegree: list[i].flatDegree, //本次表示数（平）
                        lastValleyDegree: list[i].lastValleyDegree, //上次表示数（谷）
                        valleyDegree: list[i].valleyDegree, //本次表示数（谷）
                        lastPositiveReactivePower: list[i].lastPositiveReactivePower, //上次表示数（正向无功）
                        positiveReactivePower: list[i].positiveReactivePower, //本次表示数（正向无功）
                        cmonthPositiveReactivePower: this.cTotalFromTable(i), //正向无功抄见电量（总）
                        //电费信息
                        project: list[i].elecType === 1 ? '大工业用电_1-10千伏_丰枯' : '一般工商业', //项目
                        electricPeakQuantity: this.cmonthPeakDegree(i), //电量（峰）
                        electricPeakMoneyUnit: list[i].peakPrice, //电单价（峰）
                        allPeakMoney: this.peakAmount(i), //电费（峰）
                        electricFlatQuantity: this.cmonthFlatDegree(i), //电量（平）
                        electricFlatMoneyUnit: list[i].flatPrice, //电单价（平）
                        allFlatMoney: this.flatAmount(i), //电费（平）
                        electricValleyQuantity: this.cmonthValleyDegree(i), //电量（谷）
                        electricValleyMoneyUnit: list[i].valleyPrice, //电单价（谷）
                        allValleyMoney: this.valleyAmount(i), //电费（谷）
                        powerFactorStandard: list[i].powerFactorStandard, //功率因数标准
                        factorAdjustedPowerPrice: this.factorAdjustedPowerPrice(i).adjustmentStandard, //功率因数
                        adjustmentStandard: this.factorAdjustedPowerPrice(i).adjustmentStandard, //调整系数
                        adjustmentAmount: this.adjustmentAmount(i), //调整电费
                        capacity: list[i].capacity, //用电容量
                        basePrice: list[i].basePrice, //基本电费单价
                        baseAmount: this.baseAmount(i), //基本电费
                        refundElectricityFees: list[i].refundElectricityFees, //退补电费
                        chargeAmount: this.chargeAmount(i), //收费金额合计
                        chargeAmountCNY: '',

                    };
                    powerInfoList.push(powerInfo);
                }
            }
            return powerInfoList;
        }
    },
    computed: {
        fund() {
            return numeral(this.formData.cmonthDegreeAll || 0).multiply(0.02).value();
        },
        waterMarkName() {
            if (this.formData.degreeList.length <= 0) {
                return null;
            }
            // 丰枯标志（1：枯水期；2：丰水期；3：平水期）
            const waterMark = this.formData.degreeList[0].waterMark;
            if (waterMark === 1) {
                return '枯水期';
            } else if (waterMark === 2) {
                return '丰水期';
            } else if (waterMark === 3) {
                return '平水期';
            }
            return null;
        },
        lastDataDisable() {
            return (index) => {
                const {lastPeakDegree, lastFlatDegree, lastValleyDegree} = this.formData.degreeList[index];
                return (Boolean)(lastPeakDegree || lastFlatDegree || lastValleyDegree);
            };
        },
        lastDegree() {
            return (index) => {
                return numeral(this.formData.degreeList[index].lastPeakDegree || 0)
                    .add(this.formData.degreeList[index].lastFlatDegree || 0)
                    .add(this.formData.degreeList[index].lastValleyDegree || 0)
                    .value();
            };
        },
        degree() {
            return (index) => {
                return numeral(this.formData.degreeList[index].peakDegree || 0)
                    .add(this.formData.degreeList[index].flatDegree || 0)
                    .add(this.formData.degreeList[index].valleyDegree || 0)
                    .value();
            };
        },
        //抄见电量（总）
        cmonthDegree() {
            return (index) => {
                return numeral(this.degree(index))
                    .subtract(this.lastDegree(index))
                    .multiply(this.formData.degreeList[index].electricityRate || 1)
                    .value();
            };
        },
        //
        cmonthPeakDegree() {
            return (index) => {
                return numeral(this.formData.degreeList[index].peakDegree || 0)
                    .subtract(this.formData.degreeList[index].lastPeakDegree || 0)
                    .multiply(this.formData.degreeList[index].electricityRate || 1)
                    .value();
            };
        },
        cmonthFlatDegree() {
            return (index) => {
                return numeral(this.formData.degreeList[index].flatDegree || 0)
                    .subtract(this.formData.degreeList[index].lastFlatDegree || 0)
                    .multiply(this.formData.degreeList[index].electricityRate || 1)
                    .value();
            };
        },
        cmonthValleyDegree() {
            return (index) => {
                return numeral(this.formData.degreeList[index].valleyDegree || 0)
                    .subtract(this.formData.degreeList[index].lastValleyDegree || 0)
                    .multiply(this.formData.degreeList[index].electricityRate || 1)
                    .value();
            };
        },
        cmonthPositiveReactivePower() {
            return (index) => {
                return numeral(this.formData.degreeList[index].positiveReactivePower || 0)
                    .subtract(this.formData.degreeList[index].lastPositiveReactivePower || 0)
                    .multiply(this.formData.degreeList[index].electricityRate || 1)
                    .value();
            };
        },
        //抄见电量有功(总)
        cTotalFromTable() {
            return (index) => {
                return numeral(this.degree(index))
                    .subtract(this.lastDegree(index))
                    .multiply(this.formData.degreeList[index].electricityRate || 1)
                    .value();
            };
            /*return numeral(this.formData.degreeList[index].totalFromTable || 0)
                    .subtract(this.formData.degreeList[index].lastTotalFromTable || 0)
                    .multiply(this.formData.degreeList[index].electricityRate || 1)
                    .value();
            };*/
        },
        //电费（峰）
        peakAmount() {
            return (index) => {
                return numeral(this.formData.degreeList[index].peakPrice || 0)//电费单价（峰）
                    .add(this.formData.degreeList[index].peakFundRate || 0) // 基金（峰总）
                    .multiply(this.cmonthPeakDegree(index)) //抄见电量有功（峰）
                    .format('0.00');
            };
        },
        flatAmount() {
            return (index) => {
                return numeral(this.formData.degreeList[index].flatPrice || 0)
                    .add(this.formData.degreeList[index].flatFundRate || 0)
                    .multiply(this.cmonthFlatDegree(index))
                    .format('0.00');
            };
        },
        valleyAmount() {
            return (index) => {
                return numeral(this.formData.degreeList[index].valleyPrice || 0)
                    .add(this.formData.degreeList[index].valleyFundRate || 0)
                    .multiply(this.cmonthValleyDegree(index))
                    .format('0.00');
            };
        },
        factorAdjustedPowerPrice() {
            return (index) => {
                const cmonthDegree = this.cmonthDegree(index);
                if (!cmonthDegree) {
                    return {factor: 0, adjustmentStandard: 0};
                }
                const cmonthPositiveReactivePower = this.cmonthPositiveReactivePower(index);
                const rate = cmonthPositiveReactivePower / cmonthDegree;
                //计量点（无功/有功）匹配力率表
                const factorAdjustedPowerPrice = this.factorAdjustedPowerPrices.find((item) => {
                    return item.minRate <= rate && item.maxRate >= rate;
                });
                if (factorAdjustedPowerPrice) {
                    const item = this.formData.degreeList[index];
                    let adjustmentStandard = null;
                    if (item.powerFactorStandard === 0.9) {
                        adjustmentStandard = factorAdjustedPowerPrice.adjustmentStandardOne;
                    } else if (item.powerFactorStandard === 0.85) {
                        adjustmentStandard = factorAdjustedPowerPrice.adjustmentStandardTwo;
                    } else if (item.powerFactorStandard === 0.8) {
                        adjustmentStandard = factorAdjustedPowerPrice.adjustmentStandardThree;
                    }
                    return {factor: factorAdjustedPowerPrice.factor, adjustmentStandard};
                }
                return {factor: 0, adjustmentStandard: 0};
            };
        },
        elecTypeName() {
            return (index) => {
                const elecType = this.formData.degreeList[index].elecType;
                return elecType === 1 ? '大工业用电' : '一般工商业及其他用电';
            };
        },
        // 基本电费
        baseAmount() {
            return (index) => {
                if (this.formData.degreeList[index].chargeCollectType === 2) {
                    //按需量 (基本电价*用电需量)*电价倍率
                    return numeral(this.formData.degreeList[index].basePrice * this.formData.degreeList[index].capacity)
                        .multiply(this.formData.degreeList[index].electricityRate).format('0.00');
                } else if (this.formData.degreeList[index].chargeCollectType === 1) {
                    //按容量 基本电价 * 用电容量
                    return numeral(this.formData.degreeList[index].basePrice *
                        this.formData.degreeList[index].capacity).format('0.00');
                }
            };
        },
        //抄见总 *0.044375
        totality() {
            return (index) => {
                return numeral(this.cmonthDegree(index))
                    .multiply(-0.044375)
                    .format('0.00');
            };
        },
        // 调整电费
        adjustmentAmount() {
            return (index) => {
                /*return numeral(this.cmonthDegree(index))
                    .multiply(-0.044375)*/
                //(抄见（峰+平+谷） * -0.044375 + 总电费 + 基本电费) * 调整系数
                return numeral(this.totality(index))
                    .add(this.amount(index))
                    .add(this.baseAmount(index))
                    .multiply(this.factorAdjustedPowerPrice(index).adjustmentStandard || 0)
                    .format('0.00');
            };
            /*return (index) => {
                return numeral(this.amount(index))
                    .add(this.baseAmount(index))
                    .multiply(this.factorAdjustedPowerPrice(index).adjustmentStandard || 0)
                    .format('0.00');
            };*/
        },
        //退补电费
        refundAmount() {
            return (index) => {
                return numeral(this.cmonthDegree(index)).multiply(0.02)
                    .add(this.amount(index))
                    .add(this.adjustmentAmount(index))
                    .add(this.baseAmount(index))
                    .multiply(-0.05)
                    .format('0.00');
            };
        },
        // 电费（总）
        amount() {
            return (index) => {
                return numeral(this.peakAmount(index)).add(this.flatAmount(index)).add(this.valleyAmount(index)).format('0.00');
            };
        },
        //电费发票金额
        invoiceAmount() {
            return (index) => {
                return numeral(this.amount(index))
                    .add(this.adjustmentAmount(index))
                    .add(this.baseAmount(index))
                    .add(this.formData.degreeList[index].refundElectricityFees)
                    .format('0.00');
            };
        },
        //水利基金建设费用
        /*constructionFund () {
            return numeral(this.cmonthPeakDegree(index) || 0)
                .multiply(0.02)
                .format('0.00');
        },*/
        // 收费金额
        chargeAmount() {
            return (index) => {
                return numeral(this.cTotalFromTable(index) || 0)
                    .multiply(0.02)
                    .add(this.amount(index))
                    .add(this.adjustmentAmount(index))
                    .add(this.baseAmount(index))
                    .add(this.formData.degreeList[index].refundElectricityFees)
                    .format('0.00');
                /*return numeral(this.amount(index))
                    .add(this.adjustmentAmount(index))
                    .add(this.baseAmount(index))
                    .add(this.refundAmount(index))
                    .format('0.00');*/
            };
        },
        // 本月用电总量
        cmonthDegreeAll() {
            if (this.formData.degreeList.length <= 0) {
                return 0;
            }
            return this.formData.degreeList.reduce((total, current, index) => {
                return numeral(total).add(this.cmonthDegree(index)).value();
            }, 0);
        },
        // 本月电费不包含备用电源点电费
        electricity() {
            return this.formData.degreeList.reduce((total, current, index) => {
                return numeral(total).add(this.chargeAmount(index)).value();
            }, 0);
        },
        //本月电费 + 备用电源电费
        cmonthDegreeAmount() {
            return numeral(this.electricity || 0)
                .add(this.formData.backupPower || 0)
                .format('0.00');
        },
    },
    created() {
        this.getDisabled();
        this.loadLastMonthData();
        this.listAllFactorAdjustedPowerPrice();
    }
};
</script>
<style lang="less">
    .item-card {
        border: 1px solid;
        margin: 15px 0;
        padding: 5px;
    }
</style>

