<template>
  <div>
      <el-tabs type="border-card" class="tab-form" v-model="currentTabName" style="height:100%">
          <el-tab-pane label="基本信息" name="base-info" style="height: 100%">
              <base-info ref="BaseInfo"
                         :id="customerInfoId"
                         @csustomerId="reciveCsustomerId"
                         v-if="currentTabName === 'base-info'"
              ></base-info>
          </el-tab-pane>
          <el-tab-pane label="供电信息" name="power-info" style="height: 100%">
              <power-info :id="customerInfoId"
                         @csustomerId="reciveCsustomerId"
                         v-if="currentTabName === 'power-info'"
              ></power-info>
          </el-tab-pane>
          <el-tab-pane label="计量点管理" name="change-info" style="height: 100%">
              <change-info :id="customerInfoId"
                          @csustomerId="reciveCsustomerId"
                          v-if="currentTabName === 'change-info'"
              ></change-info>
          </el-tab-pane>
          <el-tab-pane label="电费信息" name="elec-fees-info" style="height: 100%">
              <elec-fees-info :id="customerInfoId"
                          @csustomerId="reciveCsustomerId"
                          v-if="currentTabName === 'elec-fees-info'"
              ></elec-fees-info>
          </el-tab-pane>
          <el-tab-pane label="用户资料" name="attach-info" style="height: 100%">
              <attach-info :id="customerInfoId"
                           @csustomerId="reciveCsustomerId"
                           v-if="currentTabName === 'attach-info'"
              ></attach-info>
          </el-tab-pane>
      </el-tabs>
  </div>
</template>
<script>
import BaseInfo from './baseInfo';
import PowerInfo from './powerInfo';
import ChangeInfo from './changeInfo';
import ElecFeesInfo from './elecFeesInfo';
import AttachInfo from './attachInfo';

export default {
    name: 'EditCustomerForm',
    components: {
        BaseInfo,
        PowerInfo,
        ChangeInfo,
        ElecFeesInfo,
        AttachInfo
    },
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'edit'
        },
    },
    data() {
        return {
            currentTabName: 'base-info',
            csustomerId: this.id,
        };
    },
    computed: {
        customerInfoId() {
            return this.csustomerId || this.id;
        }
    },
    watch: {
        currentTabName(name) {
            if (name === 'base-info') {
                this.$emit('hideSaveButton', 'edit');
            } else {
                this.$emit('hideSaveButton', 'detail');
            }
        },
    },
    methods: {
        reciveCsustomerId(id) {
            this.csustomerId = id;
        },
        submitHandle(cb) {
            this.$refs.BaseInfo.submitHandle((rest) => {
                cb();
            });
        },
    }
};
</script>
<style lang="less" scoped>
/deep/ .el-tabs__content {
    height: 550px;
    overflow-y: auto;
}
</style>


