<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions">
            <!-- <el-button type="primary" size="mini" @click="addMeterReadingHandle">抄表</el-button>
            <el-button type="primary" size="mini" @click="addPayElectricityBillHandle">缴费</el-button>
            <el-button type="primary" size="mini" @click="editHandle">维护</el-button> -->
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1" style="margin: 0 20px 20px 20px">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getCustomerAccountList"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @current-change="selectHandle"
            >
            <template slot="append">
                <el-table-column
                    align="center"
                    label="操作"
                    type="action"
                    width="120"
                    fixed="right"
                >
                    <template slot-scope="scope">
                        <el-tooltip content="抄表" placement="top" :open-delay="200">
                            <div
                                class="search-table-icon search-table-icon_chaobiao"
                                @click="addMeterReadingHandle(scope.row)"
                                type="text"
                            ></div>
                        </el-tooltip>
                        <el-tooltip content="缴费" placement="top" :open-delay="200">
                            <div
                                class="search-table-icon search-table-icon_jiaofei"
                                @click="addPayElectricityBillHandle(scope.row)"
                                type="text"
                            ></div>
                        </el-tooltip>
                        <el-tooltip content="维护" placement="top" :open-delay="200">
                            <div
                                class="search-table-icon search-table-icon_edit"
                                @click="editHandle(scope.row)"
                                type="text"
                            ></div>
                        </el-tooltip>
                        <el-tooltip content="变更" placement="top" :open-delay="200">
                            <div
                                class="search-table-icon search-table-icon_detail"
                                @click="changeHandle(scope.row)"
                                type="text"
                            ></div>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </template>
            </ex-search-table-pagination>
        </div>
        <div class="ui-layout-content--full">
            <!--抄表-->
            <el-dialog
                v-bind="readDialog"
                width="1000px"
                :title="readDialog.title"
                v-if="readDialog.visible"
                :visible.sync="readDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <add-degree-reading-form @geIsDisable="geIsDisable" ref="addDegreeReadingForm" :customerInfo="readDialog.customerInfo" :id="readDialog.currentId" :type="readDialog.type"></add-degree-reading-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="readDialog.visible = false" size="mini">取消</el-button>
                    <el-button v-if="!isShowDisable" type="primary" @click="submitAddDegreeReadingHandle" size="mini">确定</el-button>
                </span>
            </el-dialog>
            <!--缴费-->
            <el-dialog
                v-bind="paymentDialog"
                width="820px"
                :title="paymentDialog.title"
                v-if="paymentDialog.visible"
                :visible.sync="paymentDialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <add-pay-electricity-bill-form ref="addPayElectricityBillForm" :id="paymentDialog.currentId" :type="paymentDialog.type"></add-pay-electricity-bill-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="paymentDialog.visible = false" size="mini">取消</el-button>
                    <el-button type="primary" @click="submitPayElectricityBillHandle" size="mini">确定</el-button>
                </span>
            </el-dialog>
             <el-dialog
                  width="1000px"
                  height="650px"
                  :title="editDialog.title"
                  v-if="editDialog.visible"
                  :visible.sync="editDialog.visible"
                  :modal-append-to-body="true"
                  :close-on-click-modal="false"
                  :append-to-body="true"
                  :show-close="true"
                >
                  <edit-customer-form  ref="editCustomerForm" :id="editDialog.currentId" :type="editDialog.type" @hideSaveButton="hideSaveButton"></edit-customer-form>
                  <span slot="footer" class="dialog-footer">
                      <el-button @click="editDialog.visible = false" size="mini">{{editDialog.type === 'detail' ? '关闭' : '取消'}}</el-button>
                      <el-button v-if="editDialog.type === 'edit'" type="primary" @click="submitEditHandle" size="mini">保存</el-button>
                  </span>
             </el-dialog>
        </div>
    </div>
</template>
<script>
import addPayElectricityBillForm from './addPayElectricityBillForm';
import addDegreeReadingForm from './addDegreeReadingForm';
import editCustomerForm from './editCustomerForm/index';

export default {
    name: 'CustomerAccountIndex',
    components: {
        addPayElectricityBillForm,
        addDegreeReadingForm,
        editCustomerForm
    },
    data() {
        return {
            isShowDisable: false,
            selectedRecord: null,
            readDialog: {
                visible: false,
                title: '',
                currentId: '',
                type: '',
            },
            paymentDialog: {
                visible: false,
                title: '',
                currentId: '',
                type: '',
            },
            editDialog: {
                visible: false,
                title: '',
                currentId: '',
                type: 'edit',
            },
            elecTypeList: [
                {name: '大工业', value: '1'},
                {name: '一般商业及其他、居民', value: '0'},
            ],
            statusList: [
                {name: '运营', value: 'S'},
                {name: '业务办理中', value: 'P'},
            ],
            elecNatureList: [
                {name: '永久', value: 1},
                {name: '临时', value: 2},
            ],
            loadLevelList: [
                {name: '特级负荷', value: 1},
                {name: '一级负荷', value: 2},
                {name: '二级负荷', value: 3},
                {name: '三级负荷', value: 4},
                {name: '一临时重要负荷', value: 5},
            ],
            readStatusList: [
                {name: '已抄表', value: 1},
                {name: '未抄表', value: 2},
            ],
            payStatusList: [
                {name: '无欠费', value: 1},
                /*{name: '当月未缴', value: 2},*/
                {name: '欠费', value: 3},
            ],
        };
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'customerName',
                    label: '客户名称',
                },
                {
                    prop: 'customerNumber',
                    label: '客户编号',
                    width: '150',
                },
                {
                    prop: 'status',
                    label: '业务状态',
                    formatter: (row, column, cellValue) => {
                        if (cellValue === 'S') {
                            return '运营';
                        }
                        return '业务办理';
                    }
                },
                {
                    prop: 'elecNature',
                    label: '用电性质',
                    formatter: (row, column, cellValue) => {
                        for (let obj of this.elecNatureList) {
                            if (obj.value === cellValue) {
                                return obj.name;
                            }
                        }
                        return cellValue;
                    }
                },
                {
                    prop: 'loadLevel',
                    label: '负荷级别',
                    formatter: (row, column, cellValue) => {
                        for (let obj of this.loadLevelList) {
                            if (obj.value === cellValue) {
                                return obj.name;
                            }
                        }
                        return cellValue;
                    }
                },
                {
                    prop: 'totalCapacity',
                    label: '用电容量',
                },
                {
                    prop: 'cmonthDegree',
                    label: '本月用电量',
                },
                {
                    prop: 'cmonthDegreeAmount',
                    label: '本月电费',
                },
                {
                    prop: 'cmonthPaidAmount',
                    label: '本月缴费',
                },
                {
                    prop: 'cmonthRemainAmount',
                    label: '累计欠费',
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'elecType',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择用户分类',
                        options: this.elecTypeList,
                        labelKey: 'name',
                        valueKey: 'value',
                    },
                    {
                        prop: 'currentMonthPayStatus',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择缴费情况',
                        options: this.payStatusList,
                        labelKey: 'name',
                        valueKey: 'value',
                    },
                    {
                        prop: 'status',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择业务状态',
                        options: this.statusList,
                        labelKey: 'name',
                        valueKey: 'value',
                    },

                    /*{
                        prop: 'elecNature',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择用户分类',
                        options: this.elecNatureList,
                        labelKey: 'name',
                        valueKey: 'value',
                    },*/
                    /*{
                        prop: 'loadLevel',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择负荷级别',
                        options: this.loadLevelList,
                        labelKey: 'name',
                        valueKey: 'value',
                    },*/
                    /*{
                        prop: 'currentMonthReadStatus',
                        itemType: 'select',
                        clearable: true,
                        filterable: true,
                        placeholder: '请选择当月抄表',
                        options: this.readStatusList,
                        labelKey: 'name',
                        valueKey: 'value',
                    },*/
                    {
                        prop: 'customerName',
                        itemType: 'input',
                        clearable: true,
                        placeholder: '请输入用户名称',
                    },
                ],
            };
        },
    },
    methods: {
        geIsDisable(data) {
            this.isShowDisable = data;
        },
        changeHandle(row) {
            this.$confirm('确定进行变更操作吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$client.changeBusinessApply(row.cid).then(res => {
                    this.$router.push({
                        name: 'WorkflowList',
                        params: {routeMode: true, act: 1, obj: res.data}
                    });
                });
            });
        },
        selectHandle(val) {
            this.selectedRecord = val;
        },
        preValidate() {
            if (this.selectedRecord == null || this.selectedRecord.cid == null || this.selectedRecord.cid === '') {
                this.$message({
                    type: 'warning',
                    message: '请先选择待操作数据'
                });
                return false;
            }
            return true;
        },
        addMeterReadingHandle(row) {
            // if (!this.preValidate('detail')) {
            //     return;
            // }
            this.readDialog = {
                customerInfo: row,
                title: '抄表' + '    ' + row.customerName,
                currentId: row.cid,
                visible: true,
                type: 'add',
            };
        },
        addPayElectricityBillHandle(row) {
            // if (!this.preValidate('detail')) {
            //     return;
            // }
            this.paymentDialog = {
                title: '缴费',
                currentId: row.cid,
                visible: true,
                type: 'add',
            };
        },
        editHandle(row) {
            // if (!this.preValidate('detail')) {
            //     return;
            // }
            this.editDialog = {
                currentId: row.cid,
                title: '维护',
                visible: true,
                type: 'edit'
            };
        },
        submitAddDegreeReadingHandle() {
            this.$refs.addDegreeReadingForm.submitHandle((rest) => {
                this.readDialog.visible = false;
                this.$refs.searchTable.searchHandler(rest);
            });
        },
        submitPayElectricityBillHandle() {
            this.$refs.addPayElectricityBillForm.submitHandle((rest) => {
                this.paymentDialog.visible = false;
                this.$refs.searchTable.searchHandler(rest);
            });
        },
        submitEditHandle() {
            this.$refs.editCustomerForm.submitHandle((rest) => {
                this.editDialog.visible = false;
                this.$refs.searchTable.searchHandler(rest);
            });
        },
        hideSaveButton(type) {
            this.editDialog.type = type;
        },
    },
    created() {
    }
};
</script>
