<template>
    <el-row>
        <el-col>
            <el-table
                :data="meteringList"
                style="width: 100%"
                highlight-current-row>
                <el-table-column
                    type="index"
                    label="表计资产编号"
                    width="220">
                    <template slot-scope="scope">
                    <span v-if="scope.row.isSet">
                    <el-input v-model="scope.row.powerApNumber" placeholder="请输入表计资产编号">
                    </el-input>
                    </span>
                    <span v-else>{{scope.row.powerApNumber}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="powerAp" label="计量点" width="190">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSet">
                            <el-input size="mini" maxlength="30" placeholder="请输入内容"
                                      v-model="scope.row.powerAp"></el-input>
                        </span>
                        <span v-else>{{scope.row.powerAp}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="electricityRate" label="电价倍率" width="90">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSet">
                            <el-input size="mini" type="number" class="num-rule" placeholder="请输入内容"
                                      v-model="scope.row.electricityRate"></el-input>
                        </span>
                        <span v-else>{{scope.row.electricityRate}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="businessSupplyVoltage" label="供电电压（kV）" width="115">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSet">
                            <el-input size="mini" type="number" class="num-rule" placeholder="请输入内容"
                                      v-model="scope.row.businessSupplyVoltage"></el-input>
                        </span>
                        <span v-else>{{scope.row.businessSupplyVoltage}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="chargeCollectType" label="执行电价" width="110">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSet">
                            <el-select v-model="scope.row.businessName" placeholder="请选择"
                                       @change="collectTypeChange(scope.row)">
                                <el-option
                                    v-for="item in businessName"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </span>
                        <span v-else>{{scope.row.businessName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="supplyCapacity" label="供电容量(kW/kVA)" width="135">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSet">
                            <el-input size="mini" type="number" class="num-rule" placeholder="请输入内容"
                                      v-model="scope.row.supplyCapacity"></el-input>
                        </span>
                        <span v-else>{{scope.row.supplyCapacity}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="powerFactorStandard" label="功率因素标准" width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSet">
                            <el-select v-model="scope.row.powerFactorStandard" placeholder="请选择">
                                <el-option
                                    v-for="item in powerFactorStandard"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </span>
                        <span v-else>{{powerFactorStandardName(scope.row.powerFactorStandard)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="chargeCollectType" label="基本电费收取方式" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.isSet">
                            <el-select v-model="scope.row.chargeCollectType" placeholder="请选择"
                                       :disabled="'大工业' === scope.row.businessName ? false : true">
                                <el-option
                                    v-for="item in chargeCollectType"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                >
                                </el-option>
                            </el-select>
                        </span>
                        <span v-else>{{chargeCollectTypeName(scope.row.chargeCollectType)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="90">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.isSet">
                            <label class="e-label" @click="update(scope.row)">
                                修改
                            </label>
                            <label class="e-label" @click="remove(scope.row,scope.$index)">
                                删除
                            </label>
                        </div>
                        <div v-else>
                            <label class="e-label" @click="save(scope.row)">
                                保存
                            </label>
                            <label class="e-label" @click="cancel(scope.row, scope.$index)">
                                取消
                            </label>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <el-col>
            <div class="el-table-add-row" style="width: 99.2%;" @click="addMetering()"><span>+ 添加</span></div>
        </el-col>
    </el-row>
</template>
<script>
export default {
    name: 'ChangeInfo',
    components: {},
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'detail'
        }
    },
    data() {
        return {
            electricId: null, //供电方案id
            meteringList: [],
            //基本电费收取方式
            chargeCollectType: [{
                value: 1,
                name: '按容量'
            }, {
                value: 2,
                name: '按需量'
            }],
            //功率因素执行标准
            powerFactorStandard: [{
                value: 0,
                name: '不执行'
            }, {
                value: 0.80,
                name: 0.80
            }, {
                value: 0.85,
                name: 0.85
            }, {
                value: 0.90,
                name: 0.90
            }],
            //执行电价
            businessName: [
                {value: '大工业', name: '大工业'},
                {value: '居民生活', name: '居民生活'},
                {value: '农业生产', name: '农业生产'},
                {value: '一般工商业及其他(非居民照明)', name: '一般工商业及其他(非居民照明)'},
                {value: '一般工商业及其他(非普)', name: '一般工商业及其他(非普)'},
                {value: '一般工商业及其他(非工业)', name: '一般工商业及其他(非工业)'},
                {value: '一般工商业及其他(商业)', name: '一般工商业及其他(商业)'}
            ]
        };
    },
    computed: {
        chargeCollectTypeName() {
            return function(chargeCollectType) {
                if (chargeCollectType === 1) {
                    return '按容量';
                } else if (chargeCollectType === 2) {
                    return '按需量';
                }
                return '';
            };
        },
        powerFactorStandardName() {
            return function(powerFactorStandard) {
                if (powerFactorStandard === 0) {
                    return '不执行';
                }
                return powerFactorStandard;
            };
        }
    },
    methods: {
        intiMetering() {
            //加载计量（副本）点
            this.$client.queryMeteringByCustomerId(this.id).then(result => {
                if (result.data && result.data.length > 0) {
                    for (let i = 0; i < result.data.length; i++) {
                        result.data[i].isSet = false;
                    }
                }
                this.meteringList = result.data;
            });
            if (!this.electricId) {
                //供电方案id
                this.$client.queryElectricPlanByCustomerId(this.id).then(result => {
                    if (result && result.data) {
                        this.electricId = result.data.id;
                    }
                });
            }
        },
        //添加账号
        addMetering() {
            for (let i of this.meteringList) {
                if (i.isSet) {
                    return this.$message.warning('请先保存当前编辑项');
                }
            }
            let j = {
                id: 0,
                powerApNumber: null,
                powerAp: null,
                electricityRate: null,
                businessSupplyVoltage: null,
                businessName: null,
                supplyCapacity: null,
                chargeCollectType: null,
                powerFactorStandard: null,
                electricPlanId: this.electricId,
                apId: -1,
                isSet: true
            };
            this.meteringList.push(j);
        },
        //修改,选中行变为可编辑
        update(row) {
            //点击修改 判断是否已经保存所有操作
            if (!this.canUpdate(row)) {
                this.$message.warning('请先保存当前编辑项');
                return;
            }
            row.isSet = true;
        },
        //删除
        remove(row, index) {
            if (!this.canUpdate(row)) {
                this.$message.warning('请先保存当前编辑项');
                return;
            }
            this.$confirm('确定删除计量点？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                if (row.id) {
                    row.isDelete = 1;
                    this.$client.updateMetering(row.id, row).then(result => {
                        this.meteringList.splice(index, 1);
                    });
                }
            }).catch(() => {
            });
        },
        //保存
        save(row) {
            if (!this.validate(row)) {
                return;
            }
            if (row.id) {
                this.$client.updateMetering(row.id, row).then(result => {
                    row.isSet = false;
                });
            } else {
                this.$client.saveMingCopy(row).then(result => {
                    row.id = result.data.id;
                    row.isSet = false;
                });
            }
        },
        //取消
        cancel(row, index) {
            if (!row.id) {
                this.meteringList.splice(index, 1);
            }
            row.isSet = false;
        },
        //判断是否可编辑
        canUpdate(row) {
            let b = true;
            for (let i of this.meteringList) {
                if (i.isSet && i.id !== row.id) {
                    b = false;
                    break;
                }
            }
            return b;
        },
        //数据校验
        validate(row) {
            if (row.powerAp === null) {
                this.$message.warning('请填写计量点');
                return false;
            }
            if (row.businessSupplyVoltage === null) {
                this.$message.warning('请填写供电电压');
                return false;
            }
            if (row.businessName === null) {
                this.$message.warning('请填写执行电价');
                return false;
            }
            if (row.supplyCapacity === null) {
                this.$message.warning('请填写供电容量');
                return false;
            }
            if (row.businessName === '大工业' && !row.chargeCollectType) {
                this.$message.warning('请选择电费收取方式');
                return false;
            }
            return true;
        },
        collectTypeChange(row) {
            if (row.chargeCollectType && row.chargeCollectType === '大工业') {
                return;
            }
            row.chargeCollectType = 2;
        }
    },
    created() {
        this.intiMetering();
    }
};
</script>
<style lang="less" scoped>
    .el-table-add-row {
        margin-top: 10px;
        width: 100%;
        height: 34px;
        border: 1px dashed #c1c1cd;
        border-radius: 3px;
        cursor: pointer;
        justify-content: center;
        display: flex;
        line-height: 34px;
    }

    .e-label {
        cursor: pointer;
        margin-right: 3px;
    }

    /deep/ .num-rule input::-webkit-outer-spin-button,
    /deep/ .num-rule input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }

    /deep/ .num-rule input[type="number"] {
        -moz-appearance: textfield;
    }
</style>
