<template>
    <div class="ui-layout-container ui-layout-flex ui-layout-flex--column ui-height-100">
        <div class="ui-layout-content--full ui-form-actions">
            <el-row :gutter="24">
                <el-col :span="5">
                    累计电费：{{sumData.degreeAmountSum}} 元
                </el-col>
                <el-col :span="5">
                    累计缴纳：{{sumData.paidAmountSum}} 元
                </el-col>
                <el-col :span="5">
                    累计欠费：{{sumData.remainAmountSum}} 元
                </el-col>
                <el-col :span="9">
                </el-col>
            </el-row>
        </div>
        <div class="ui-layout-content--full ui-layout-flex--1 ui-height-100" style="margin-top:15px;">
            <ex-search-table-pagination
                ref="searchTable"
                :fetch="$client.getCustomerPaymentBills"
                :columns="columns"
                :formOptions="formOptions"
                :searchWhenSortChange="true"
                @selection-change="selectHandle"
                :paramsMethod="searchParams"
            >
                <template slot="append">
                    <el-table-column label="抄表信息" align="center" width="80px">
                        <template slot-scope="scope">
                            <i class="el-icon-picture" title="查看抄表信息" @click="viewPaymentBillAttachemnet(scope.row.cid, scope.row.recordMonth)"></i>
                        </template>
                    </el-table-column>
                </template>
            </ex-search-table-pagination>
        </div>
        <div class="ui-layout-content--full">
            <el-dialog
                width="700px"
                :title="dialog.title"
                v-if="dialog.visible"
                :visible.sync="dialog.visible"
                :modal-append-to-body="true"
                :close-on-click-modal="false"
                :append-to-body="true"
                :show-close="true"
            >
                <view-degree-read-info ref="ViewDegreeReadInfo" :id="dialog.currentId" :month="dialog.month" :type="dialog.type"></view-degree-read-info>
                <span slot="footer" class="dialog-footer">
                <el-button
                    @click="closeModalHandle"
                    size="mini"
                >{{dialog.type === 'detail' ? '关闭' : '取消'}}</el-button>
            </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import ViewDegreeReadInfo from './viewDegreeReadInfo';

export default {
    name: 'ElecFeesInfo',
    components: {ViewDegreeReadInfo},
    component: {
        ViewDegreeReadInfo,
    },
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'add'
        },
    },
    data() {
        return {
            selectedRows: [],
            dialog: {
                visible: false,
                title: '',
                currentId: '',
                month: '',
                type: '',
            },
            sumData: {
                degreeAmountSum: 0,
                paidAmountSum: 0,
                remainAmountSum: 0,
            },
        };
    },
    computed: {
        columns() {
            return [
                {
                    prop: 'recordMonth',
                    width: 80,
                    label: '抄表月份',
                },
                {
                    prop: 'cmonthDegree',
                    label: '本月用电(kVH)',
                },
                {
                    prop: 'cmonthDegreeAmount',
                    label: '本月电费(元)',
                },
                {
                    prop: 'lmonthArrearsAmount',
                    label: '上月欠费(元)',
                },
                {
                    prop: 'cmonthDueAmounnt',
                    label: '本月应缴(元)',
                },
                {
                    prop: 'cmonthPaidAmount',
                    label: '本月实缴(元)',
                },
                {
                    prop: 'cmonthRemainAmount',
                    label: '本月待缴(元)',
                },
            ];
        },
        formOptions() {
            return {
                forms: [
                    {
                        prop: 'recordMonth',
                        itemType: 'month',
                        label: '抄表月份',
                        placeholder: '请选择抄表月份',
                        'value-format': 'yyyy-MM'
                    },
                ],
            };
        },
    },
    methods: {
        searchParams(params) {
            params.cid = this.id;
            return params;
        },
        selectHandle(val) {
            this.selectedRows = val;
        },
        closeModalHandle() {
            this.dialog.visible = false;
        },
        viewPaymentBillAttachemnet(cid, recordMonth) {
            this.dialog = {
                currentId: cid,
                month: recordMonth,
                title: '抄表记录',
                visible: true,
                type: 'detail'
            };
        },
        submitHandle() {
            this.$refs.editForm.submitHandle((rest) => {
                this.closeModalHandle();
                this.$refs.searchTable.searchHandler(rest);
            });
        },
        initData() {
            //获取汇总数据
            this.$client.getCustomerPaymentBillSum(this.id).then(result => {
                this.sumData = result.data;
            }).catch(e => {
            });
        },
    },
    created() {
        this.initData();
    }
};
</script>
<style lang="less" scoped>
</style>
