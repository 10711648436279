<template>
    <div>
        <div class="ui-layout-content--narrow ui-layout-flex ui-layout-flex--column">
            <el-row :gutter="24">
                <el-col>
                    本月用电量: {{formData.cmonthDegreeAll}} kWVH
                </el-col>
            </el-row>
            <div v-for="(item,index) in formData.degreeList" :key="index">
                <el-row :gutter="24" style="margin-top: 15px;">
                    <el-col>
                        计量点{{index+1}}: {{item.planMeteringName}}
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col :span="8">
                        上月电表度数：{{item.lastDegree}} kWVH
                    </el-col>
                    <el-col :span="8">
                        本月电表度数：{{item.degree}} kWVH
                    </el-col>
                    <el-col :span="8">
                        本月用电量：{{item.cmonthDegree}} kWVH
                    </el-col>
                </el-row>
                <el-row :gutter="24">
                    <el-col>
                        <ex-uploader v-model="item.file"  :disabled=true></ex-uploader>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
    name: 'ViewDegreeReadInfo',
    components: {},
    props: {
        id: {
            type: Number,
            default: null
        },
        month: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'add'
        },
    },
    data() {
        return {
            formData: {
            },
        };
    },
    computed: {},
    methods: {
        initData() {
            //加载当前月抄表所需数据
            let recordMonth;
            if (this.month && this.month > 0) {
                let monthTemp = dayjs(this.month.toString(), 'YYYYMM');
                recordMonth = dayjs(monthTemp).format('YYYY-MM');
            }
            const param = {
                recordMonth,
                id: this.id
            };
            this.$client.getCustomerDegreeRecord(param).then(result => {
                this.formData = result.data;
            });
        },
    },
    created() {
        this.initData();
    }
};
</script>
<style lang="less" scoped>
</style>
