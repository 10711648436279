<template>
    <div style="height: 550px;">
        <div class="ui-layout-content--narrow ui-layout-flex">
            <el-form
                class="base-info__form ui-layout-flex--1"
                :model="formData"
                :rules="formRules"
                size="mini"
                :inline="true"
                ref="form"
                label-position="top"
                :disabled="type === 'detail'"
            >
                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item prop="name"
                                      label="客户名称"
                                      style="width: 100%">
                            <el-input v-model="formData.customerName"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="number"
                                      label="客户编号"
                                      style="width: 100%">
                            <el-input v-model="formData.customerNumber"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item :label="'客户身份'" prop="customerIdentity" style="width: 100%">
                            <el-select v-model="formData.customerIdentity" :disabled=true :clearable="true" placeholder="请选择" style="width: 100%">
                                <el-option v-for="item in keyValues.customerIdentity" :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="number"
                                      label="用电地址"
                                      style="width: 100%">
                            <el-input v-model="formData.useAddr"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="idNumber"
                                      label="证件号码"
                                      style="width: 100%">
                            <el-input v-model="formData.idNumber"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="agent"
                                      label="业务经办人"
                                      style="width: 100%">
                            <el-input v-model="formData.agent"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="agentTel"
                                      label="业务经办人联系电话"
                                      style="width: 100%">
                            <el-input v-model="formData.agentTel"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="agentNo"
                                      label="业务经办人身份证"
                                      style="width: 100%">
                            <el-input v-model="formData.agentNo"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="useRelName"
                                      label="用电联系人"
                                      style="width: 100%">
                            <el-input v-model="formData.userElName"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="useRelTel"
                                      label="用电联系人联系电话"
                                      style="width: 100%">
                            <el-input v-model="formData.useEelTel"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="chargeRelName"
                                      label="电费联系人"
                                      style="width: 100%">
                            <el-input v-model="formData.chargeRelName"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="chargeRelTel"
                                      label="电费联系人联系电话"
                                      style="width: 100%">
                            <el-input v-model="formData.chargeRelTel"
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="billEmail"
                                      label="电子账单邮箱地址"
                                      style="width: 100%">
                            <el-input v-model="formData.billEmail"
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="smsTel"
                                      label="电子账单接收手机号"
                                      style="width: 100%">
                            <el-input v-model="formData.smsTel"
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="'电费担保方式'" prop="elecAssureType" style="width: 100%">
                            <el-select v-model="formData.elecAssureType" :clearable="true" placeholder="请选择" style="width: 100%">
                                <el-option v-for="item in keyValues.elecAssureType" :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item prop="useAddr"
                                      label="用电地址"
                                      style="width: 100%">
                            <el-input v-model="formData.useAddr"
                                      :disabled=true
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="zipCode"
                                      label="邮政编码"
                                      style="width: 100%">
                            <el-input v-model="formData.zipCode"
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item prop="contactAddr"
                                      label="通邮地址"
                                      style="width: 100%">
                            <el-input v-model="formData.contactAddr"
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="8">
                        <el-form-item prop="faxes"
                                      label="传真"
                                      style="width: 100%">
                            <el-input v-model="formData.faxes"
                                      style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseInfo',
    components: {},
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'add'
        },
    },
    data() {
        return {
            loading: false,
            domesticRegionShow: false,
            formData: {
            },
            formRules: {},
            keyValues: {
                customerIdentity: [
                    {
                        value: 0,
                        label: '产权人（业主）'
                    },
                    {
                        value: 1,
                        label: '租赁户'
                    },
                ],
                elecAssureType: [
                    {
                        value: 1,
                        label: '银行保函'
                    },
                    {
                        value: 2,
                        label: '单位担保'
                    },
                    {
                        value: 3,
                        label: '个人担保'
                    },
                    {
                        value: 4,
                        label: '不动产抵押'
                    },
                    {
                        value: 5,
                        label: '动产抵押'
                    },
                ],
            },
        };
    },
    computed: {},
    methods: {
        submitHandle(cb) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const data = {...this.formData};
                    this.$client.saveCustomerInfo(data).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success',
                        });
                        cb();
                    });
                }
            });
        },
        initCustomerInfo() {
            //加载用户信息
            this.$client.getCustomerInfo(this.id).then(result => {
                this.formData = result.data.info;
            }).catch(e => {
            });
        },
    },
    created() {
        this.initCustomerInfo();
    }
};
</script>
<style lang="less" scoped>
</style>

