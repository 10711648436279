import { render, staticRenderFns } from "./attachInfo.vue?vue&type=template&id=3fac7904&scoped=true&"
import script from "./attachInfo.vue?vue&type=script&lang=js&"
export * from "./attachInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fac7904",
  null
  
)

export default component.exports