<template>
    <div style="height: 550px;">
        <div class="ui-layout-content--narrow ui-layout-flex">
            <el-form
                class="base-info__form ui-layout-flex--1"
                :model="formData"
                :rules="formRules"
                size="mini"
                :inline="true"
                ref="form"
                label-position="top"
                :disabled="type === 'detail'"
            >
                <el-row :gutter="24">
                    <el-col :span="8">
                        <el-form-item :label="'客户类型'" prop="customerType" style="width: 100%">
                            <el-select v-model="formData.customerType"  :disabled=true :clearable="true" placeholder="请选择" style="width: 100%">
                                <el-option v-for="item in keyValues.customerType" :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="'客户行业'"  prop="customerIndustry" style="width: 100%">
                            <el-select v-model="formData.customerIndustry" multiple  :disabled=true :clearable="true" placeholder="请选择" style="width: 100%">
                                <el-option v-for="item in keyValues.customerIndustry" :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="'用电类别'"  prop="elecType" style="width: 100%">
                            <el-select v-model="formData.elecType" :disabled=true :clearable="true" placeholder="请选择" style="width: 100%">
                                <el-option v-for="item in keyValues.elecType" :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="'供电电压'"  prop="voltage" style="width: 100%">
                            <el-select v-model="formData.voltage"  :disabled=true :clearable="true" placeholder="请选择" style="width: 100%">
                                <el-option v-for="item in keyValues.voltage" :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="'用电性质'"  prop="elecNature" style="width: 100%">
                            <el-select v-model="formData.elecNature"  :disabled=true :clearable="true" placeholder="请选择" style="width: 100%">
                                <el-option v-for="item in keyValues.elecNature" :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item :label="'负荷级别'"  prop="loadLevel" style="width: 100%">
                            <el-select v-model="formData.loadLevel"  :disabled=true :clearable="true" placeholder="请选择" style="width: 100%">
                                <el-option v-for="item in keyValues.loadLevel" :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="highPowerCapacity" label="高可靠性供电容量" style="width: 100%">
                            <el-input v-model="reconnaissanceData.highPowerCapacity" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="transformerNumber" label="变压器数量" style="width: 100%">
                            <el-input type="textarea" :rows="1" v-model="reconnaissanceData.transformerCapacitysStr" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="transformerCapacitys" label="变压器总容量" style="width: 100%">
                            <el-input v-model="reconnaissanceData.transformerAllCapacitys" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item prop="useEels" label="用电设备" style="width: 100%">
                            <el-input type="textarea" :rows="1" v-model="formData.useEels" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="useTotalCapacity" label="用电负荷合计(KW)" style="width: 100%">
                            <el-input v-model="formData.useTotalCapacity" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item prop="equipment_total_capacity" label="装见容量合计(KVA)" style="width: 100%">
                            <el-input v-model="formData.equipmentTotalCapacity" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    <div v-for="(item,index) in planData.investmentArray" :key="index">
                        <el-col :span="24">
                        <el-form-item prop="item.value" :label="'供电电源'+(index+1)" style="width: 100%">
                            <el-input type="textarea" :rows="1" v-model="item.value" :disabled=true style="width: 100%"></el-input>
                        </el-form-item>
                    </el-col>
                    </div>
                    <el-col :span="8">
                        <el-form-item :label="'变电所所址型式'"  prop="electricSubstationAddr" style="width: 100%">
                            <el-select v-model="planData.electricSubstationAddr"  :disabled=true :clearable="true" placeholder="请选择" style="width: 100%">
                                <el-option v-for="item in keyValues.electricSubstationAddr" :key="item.value"
                                           :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                     <el-col :span="8">
                         <el-form-item :label="'高压侧电气主接线方式'"  prop="electricAccessWay" style="width: 100%">
                             <el-select v-model="planData.electricAccessWay"  :disabled=true :clearable="true" placeholder="请选择" style="width: 100%">
                                 <el-option v-for="item in keyValues.electricAccessWay" :key="item.value"
                                            :label="item.label" :value="item.value">
                                 </el-option>
                             </el-select>
                         </el-form-item>
                    </el-col>
                    <div v-for="(item,index) in planData.meteringArray" :key="index">
                        <el-col :span="24">
                            <el-form-item prop="item.value" :label="'计量点'+(index+1)" style="width: 100%">
                                <el-input type="textarea" :rows="1" v-model="item.value" :disabled=true style="width: 100%"></el-input>
                            </el-form-item>
                        </el-col>
                    </div>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PowerInfo',
    components: {},
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'add'
        },
    },
    data() {
        return {
            loading: false,
            domesticRegionShow: false,
            formData: {
                useEels: '',
            },
            reconnaissanceData: {
            },
            planData: {
                investmentArray: [],
                meteringArray: [],
            },
            formRules: {},
            keyValues: {
                customerType: [
                    {value: 0, label: '非居民客户'},
                    {value: 1, label: '居民客户'},
                ],
                customerIndustry: [
                    {value: '10', label: '煤矿客户'},
                    {value: '20', label: '易燃易爆化工项目客户'},
                    {value: '30', label: '采石场（矿）客户'},
                    {value: '40', label: '新建住宅小区'},
                    {value: '50', label: '特殊要求客户'},
                    {value: '60', label: '政府重要电力用户'},
                    {value: '70', label: '非线性负荷设备'},
                ],
                voltage: [
                    {value: 1, label: '220V'},
                    {value: 2, label: '380V'},
                    {value: 3, label: '10kV'},
                    {value: 4, label: '20kV'},
                    {value: 5, label: '35kV'},
                    {value: 6, label: '110kV及以上'},
                ],
                elecNature: [
                    {value: 1, label: '永久'},
                    {value: 2, label: '临时'},
                ],
                loadLevel: [
                    {value: 1, label: '特级负荷'},
                    {value: 2, label: '一级负荷'},
                    {value: 3, label: '二级负荷'},
                    {value: 4, label: '三级负荷'},
                    {value: 5, label: '临时性重要负荷'},
                ],
                elecType: [
                    {value: 1, label: '大宗工业'},
                    {value: 2, label: '普通工业'},
                    {value: 3, label: '非工业'},
                    {value: 4, label: '住宅'},
                    {value: 5, label: '商业'},
                    {value: 6, label: '农业生产'},
                    {value: 7, label: '稻田排灌'},
                    {value: 8, label: '其它'},
                ],
                electricSubstationAddr: [
                    {value: 1, label: '杆上台变'},
                    {value: 2, label: '配电室内'},
                    {value: 3, label: '箱式变电站'},
                ],
                electricAccessWay: [
                    {value: 1, label: '单母线'},
                    {value: 2, label: '单母分段'},
                    {value: 3, label: '双母线'},
                    {value: 4, label: '其他'},
                ],
            }
        };
    },
    computed: {},
    methods: {
        submitHandle() {
        },
        initCustomerInfo() {
            //加载用户信息
            this.$client.getCustomerInfo(this.id).then(result => {
                this.formData = result.data.info;
                this.reconnaissanceData = result.data.reconnaissance;
                this.planData = result.data.elecPlan;
                if (result.data.info.equipmentCapacityForms && result.data.info.equipmentCapacityForms.length > 0) {
                    let equipmentStr = '';
                    for (let item of result.data.info.equipmentCapacityForms) {
                        equipmentStr += '设备名称：' + item.equipmentName + ' ';
                        equipmentStr += '型号：' + item.equipmentType + ' ';
                        equipmentStr += '额定电压：' + item.nominalVoltage + 'KV ';
                        equipmentStr += '容量：' + item.singleCapacity + 'KW * ' + item.num + ' ';
                        equipmentStr += '容量小计：' + item.capacityCount + ' ';
                    }
                    this.formData.useEels = equipmentStr;
                }
                if (result.data.elecPlan.electricPlanMeterings && result.data.elecPlan.electricPlanMeterings.length > 0) {
                    let investmentArray = [];
                    let meteringArray = [];
                    for (let item of result.data.elecPlan.electricPlanMeterings) {
                        let investmentStr = [];
                        let meteringStr = [];
                        investmentStr.push('是否是主电源：');
                        if (item.isInvestmentInterface && item.isInvestmentInterface === 1) {
                            investmentStr.push('是');
                        } else {
                            investmentStr.push('否');
                        }
                        investmentStr.push(' ');
                        //TODO 缺变电站、塔接点、设备
                        investmentStr.push('供电电压：');
                        if (item.supplyVoltage) {
                            investmentStr.push(item.supplyVoltage);
                            investmentStr.push('kV、');
                        }
                        investmentStr.push(' ');
                        investmentStr.push('供电容量：');
                        if (item.supplyCapacity) {
                            investmentStr.push(item.supplyCapacity);
                            investmentStr.push('kW/kVA ');
                        }
                        investmentArray.push({key: '', value: investmentStr.join('')});

                        meteringStr.push('计量点号：');
                        if (item.meteringNo) {
                            meteringStr.push(item.meteringNo);
                        }
                        meteringStr.push(' ');
                        meteringStr.push('计量方式：');
                        if (item.meteringType && item.meteringType === 1) {
                            meteringStr.push('高供高计');
                        } else if (item.meteringType && item.meteringType === 2) {
                            meteringStr.push('高供低计');
                        } else if (item.meteringType && item.meteringType === 1) {
                            meteringStr.push('低供低计');
                        }
                        meteringStr.push(' ');
                        meteringStr.push('计量点位置：');
                        if (item.meteringPos) {
                            meteringStr.push(item.meteringPos);
                        }
                        meteringStr.push(' ');
                        meteringStr.push('电压互感器变化比：');
                        if (item.meteringDeviceV) {
                            meteringStr.push(item.meteringDeviceV);
                        }
                        meteringStr.push(' ');
                        meteringStr.push('电流互感器变化比：');
                        if (item.meteringDeviceA) {
                            meteringStr.push(item.meteringDeviceA);
                        }
                        meteringStr.push(' ');
                        meteringArray.push({key: '', value: meteringStr.join('')});
                    }
                    this.planData.investmentArray = investmentArray;
                    this.planData.meteringArray = meteringArray;
                }
            }).catch(e => {
            });
        },
    },
    created() {
        this.initCustomerInfo();
    }
};
</script>
<style lang="less" scoped>
.base-info__pic {
    width: 200px;
    height: auto;
    margin-left: 20px;
    & > img {
        width: 100%;
    }
}
</style>

