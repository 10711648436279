<template>
    <div>
        <el-card class="card-pub-notice card-ext-center-title" :headerName="item.datumName" v-for="item in datumData" :key="item.id">
            <div slot="header" class="notice_title">
                <span>{{item.datumName}}</span>
            </div>
            <ex-uploader v-model="item.files"  :disabled=true></ex-uploader>
        </el-card>
    </div>
</template>
<script>
export default {
    name: 'AttachInfo',
    components: {},
    props: {
        id: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'detail'
        },
    },
    data() {
        return {
            datumData: [],
        };
    },
    computed: {},
    methods: {
        initCustomerInfo() {
            //加载用户信息
            this.$client.getCustomerInfo(this.id).then(result => {
                this.datumData = result.data.datum;
            }).catch(e => {
            });
        },
    },
    created() {
        this.initCustomerInfo();
    }
};
</script>
<style lang="less" scoped>
</style>
