<template>
    <div>
        <el-form
            :model="formData"
            :rules="formRules"
            ref="form"
            size="mini"
            :disabled="type === 'detail'"
        >
            <el-row :gutter="24">
                <el-col :span="12">
                    <el-form-item :label="'缴费月份'" prop="recordMonth">
                        <el-date-picker
                            v-model="formData.recordMonth"
                            style="width: 100%"
                            type="month"
                            :placeholder="'请选择'"
                            value-format="yyyy-MM"
                            @change="switchMonthHandler"
                        ></el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="'缴费金额'" prop="cmonthPaidAmount">
                        <el-input-number v-model="formData.cmonthPaidAmount" :min="0" :precision="2" :step="1" style="width: 100%"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row class="item-card">
                <el-col>
                    抄表月份：{{paymentBill.recordMonth}}
                </el-col>
                <el-col>
                    <el-row :gutter="12">
                        <el-col :span="6">
                            当月电费：{{paymentBill.cmonthDegreeAmount}}
                        </el-col>
                        <el-col :span="6">
                            历史欠费：{{paymentBill.lmonthArrearsAmount}}
                        </el-col>
                        <el-col :span="6">
                            本月应缴：{{paymentBill.cmonthDueAmounnt}}
                        </el-col>
                        <el-col :span="6">
                            剩余待缴：{{paymentBill.cmonthRemainAmount}}
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <el-row :gutter="24">
                <div style="margin: 15px">
                </div>
            </el-row>
            <el-row :gutter="24">
                <el-col :span="24">
                    <el-form-item :label="'缴费凭证'" prop="files">
                        <div class="change-line">
                            <ex-uploader v-model="formData.files"></ex-uploader>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    name: 'addPayElectricityBillForm',
    props: {
        id: {
            type: Number,
            default: null,
        },
        type: {
            type: String,
            default: 'add',
        },
    },
    data() {
        let thisMonth = function doHandleMonth() {
            let myDate = new Date();
            let tMonth = myDate.getMonth();
            let tYear = myDate.getFullYear();
            let m = tMonth + 1;
            if (m.toString().length === 1) {
                m = '0' + m;
            }
            return tYear + '-' + m;
        };
        let validatorHasNumber = function(rule, value, callback) {
            if (value === '' || value === null || value === '0.0' || value === '0.00') {
                return callback(new Error('不能为空或为0！'));
            } else if (!/(^[1-9]{1}[0-9]*$)|(^[0-9]*\.[0-9]{2}$)/.test(value) && value < 0.01) {
                return callback(new Error('必须大于0，且最多保留2位小数!'));
            }
            callback();
        };
        return {
            paymentBill: {
                recordMonth: '',
                cmonthDegreeAmount: null,
                lmonthArrearsAmount: null,
                cmonthDueAmounnt: null,
                cmonthRemainAmount: null,
            },
            formData: {
                cid: this.id,
                recordMonth: thisMonth(),
                cmonthPaidAmount: 0,
                files: [],
            },
            formRules: {
                recordMonth: {
                    required: true,
                    message: this.$t('common.select', {
                        item: '缴费月份',
                    }),
                    trigger: 'blur',
                },
                cmonthPaidAmount: {
                    required: true,
                    validator: validatorHasNumber,
                    trigger: 'blur',
                },
                files: {
                    required: false,
                    message: '请上传缴费凭证！',
                    trigger: 'blur',
                }
            },
        };
    },
    methods: {
        uploadFileSucceed(res) {
            const {data} = res;
            this.formData.files.push(data.id);
            this.fileInfos.push(data);
        },
        switchMonthHandler() {
            this.initData();
        },
        submitHandle(cb) {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const data = {...this.formData};
                    this.$client.addCustomerPaymentRecord(data).then(() => {
                        this.$message({
                            message: '操作成功！',
                            type: 'success',
                        });
                        cb();
                    });
                }
            });
        },
        initData() {
            //加载当前月抄表所需数据
            const param = {
                recordMonth: this.formData.recordMonth,
                id: this.id
            };
            this.$client.getCustomerCurrentPaymentBill(param).then(result => {
                this.paymentBill = result.data;
            }).catch(e => {
            });
        },
    },
    computed: {
    },
    created() {
        this.initData();
    }
};
</script>
<style lang="less">
    .item-card {
        border: 1px solid;
        margin: 15px 0;
        padding: 5px;
    }
    .change-line {
        float: left;
        display: inline-block;
        width: 100%;
        margin-left: 10px;
        margin-top: 3px;
    }
</style>

